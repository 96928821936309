import dialogReducer from '@/components/dialog'
import profilesReducer from '@/contexts/reducers/ProfilesReducer'
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    dialog: dialogReducer,
    profiles: profilesReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
