import(/* webpackMode: "eager", webpackExports: ["DialogContainer"] */ "/vercel/path0/components/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/i18n/lang-context-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/components/theme/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/components/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GitIndicator"] */ "/vercel/path0/components/utils/git-indicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConsentContextProvider"] */ "/vercel/path0/contexts/ConsentContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/vercel/path0/contexts/reducers/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.scss");
