'use client'

import { useTheme } from 'next-themes'
import { Theme, ToastContainer as ToastifyContainer } from 'react-toastify'

export function ToastContainer() {

  const { theme, systemTheme } = useTheme()

  return (
    <ToastifyContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      theme={(theme === 'system' ? systemTheme : theme) as Theme} />
  )
}
