
'use client'

import { devMode } from '@/lib/utils/utils'
import Link from 'next/link'
import { useState } from 'react'
import { LuGitBranch, LuGitCommit, LuGithub } from 'react-icons/lu'
import { RxPerson } from 'react-icons/rx'


const REPO_OWNER = process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER || 'jarvix-src'
const REPO_NAME = process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG || 'jvx-template'
const COMMIT_BRANCH = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'main'
const COMMIT_SHA = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'abcdefghijklmnopqrstuvwxyz'
const COMMIT_MESSAGE = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE || 'Local fake commit'
const COMMIT_AUTHOR = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN || 'limax84'

const GIT_BASE_URL = 'https://github.com'
const REPO_URL = `${GIT_BASE_URL}/${REPO_OWNER}/${REPO_NAME}`
const BRANCH_URL = `https://github.com/${REPO_OWNER}/${REPO_NAME}/tree/${COMMIT_BRANCH}`
const COMMIT_URL = `https://github.com/${REPO_OWNER}/${REPO_NAME}/commit/${COMMIT_SHA}`
const AUTHOR_URL = `https://github.com/${COMMIT_AUTHOR}`


export function GitIndicator() {

  const [show, setShow] = useState<boolean>(false)

  if (!devMode()) return null

  /* eslint-disable */
  return (
    <div className='fixed bottom-1 right-1 z-50 flex h-fit w-fit items-center justify-center overflow-visible rounded-full bg-gray-800 font-mono text-xs text-white'>
      <div className='absolute bottom-0 right-0 flex origin-bottom-right flex-col space-y-2 rounded-md bg-gray-800
      p-8 text-sm font-normal text-white opacity-80 transition-all' style={{
          scale: show ? 1 : 0,
          opacity: show ? 1 : 0
        }}>
        <div className='flex w-full flex-nowrap items-center justify-start gap-2'>
          <LuGitBranch size={16} />
          <Link href={REPO_URL} target='_blank' rel='noreferrer' className='whitespace-nowrap hover:underline'>{REPO_NAME}</Link>
          <Link href={BRANCH_URL} target='_blank' rel='noreferrer' className='whitespace-nowrap hover:underline'> ({COMMIT_BRANCH})</Link>
        </div>
        <div className='flex w-full flex-nowrap items-center justify-start gap-2'>
          <LuGitCommit size={16} />
          <Link href={COMMIT_URL} target='_blank' rel='noreferrer' className='flex flex-row hover:underline'>
            {COMMIT_SHA.substring(0, 6)}&nbsp;|&nbsp;<div className='max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap'>{COMMIT_MESSAGE}</div>
          </Link>
        </div>
        <div className='flex w-full flex-nowrap items-center justify-start gap-2'>
          <RxPerson size={16} />
          <Link href={AUTHOR_URL} target='_blank' rel='noreferrer' className='whitespace-nowrap hover:underline'> {COMMIT_AUTHOR}</Link>
        </div>
      </div>
      <LuGithub onClick={() => setShow(_show => !_show)} size={16}
        className='z-10 m-1 cursor-pointer text-gray-300 transition-all hover:text-white'
        style={{
          scale: show ? 1.5 : 1,
          marginBottom: show ? '10px' : '4px',
          marginRight: show ? '10px' : '4px'
        }} />
    </div>
  )
  /* eslint-enable */
}
