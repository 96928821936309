// Actions ------------------------------------------------------------------

import { RootState } from '@/app/store'
import { Profile } from '@/lib/commons/profile'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

export const ADD_PROFILE = 'ADD_PROFILE'
export const ADD_PROFILES = 'ADD_PROFILES'
export const REMOVE_PROFILE = 'REMOVE_PROFILE'
export const REMOVE_PROFILES = 'REMOVE_PROFILES'

const addProfile = (profile: Profile): AnyAction => ({
  type: ADD_PROFILE,
  payload: profile
})

const addProfiles = (profiles: Profile[]): AnyAction => ({
  type: ADD_PROFILES,
  payload: profiles
})

const removeProfile = (profile: Profile): AnyAction => ({
  type: REMOVE_PROFILE,
  payload: profile
})

const removeProfiles = (): AnyAction => ({
  type: REMOVE_PROFILES
})


// Reducer ----------------------------------------------------------------

type ProfilesState = Profile[]

const initialState: ProfilesState = []

const profilesReducer = (state = initialState, action: AnyAction): ProfilesState => {
  switch (action.type) {
    case ADD_PROFILE:
      console.log('Caching profile', action.payload)
      return [...state, action.payload]
    case ADD_PROFILES:
      console.log('Caching profiles', action.payload)
      return [...state, ...action.payload]
    case REMOVE_PROFILE:
      return state.filter((profile) => profile !== action.payload)
    case REMOVE_PROFILES:
      return []
    default:
      return state
  }
}

export default profilesReducer


// Hook ---------------------------------------------------------------

export const useProfiles = () => {
  return useSelector((state: RootState) => state.profiles)
}

export const useProfilesActions = () => {
  const dispatch = useDispatch()
  return {
    addProfile: (profile: Profile) => dispatch(addProfile(profile)),
    addProfiles: (profiles: Profile[]) => dispatch(addProfiles(profiles)),
    removeProfile: (profile: Profile) => dispatch(removeProfile(profile)),
    removeProfiles: () => dispatch(removeProfiles())
  }
}
